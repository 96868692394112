<template>
  <v-expansion-panels accordion>
    <v-expansion-panel
      v-for="article in articles"
      :key="article.id"
    >
      <v-expansion-panel-header>
        {{ article.title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="support_article"
        v-html="article.description"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  props: {
    folder: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      articles: [],
    }
  },
  mounted () {
    var credentials = btoa("8MBTESGn3YM3BrUI8t:X");
    var auth = { "Authorization" : `Basic ${credentials}` };
    fetch(`https://arkstone.freshdesk.com/api/v2/solutions/folders/${this.folder}/articles`, { headers : auth })
      .then(response => response.json())
      .then(data => {
        this.articles = data
      })
  },
}
</script>
<style>
  .support_article > p {
    width: 100%;
  }
</style>